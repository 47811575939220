<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import { InputProps } from '~/components/form/input/types'

defineOptions({
  inheritAttrs: false,
})

const props = defineProps<InputProps>()
const emit = defineEmits<{
  (event: 'update:modelValue', val: any): void
}>()

const attrs = useAttrs()
const attrsType = computed(() => attrs.type)
const isTypePassword = computed(() => attrsType.value === 'password')
const isPassword = ref(true)
const type = computed(() =>
  isTypePassword.value ? (isPassword.value ? 'password' : 'text') : attrsType.value
)

const modelValue = ref(props.modelValue)

watchEffect(() => {
  modelValue.value = props.modelValue
})

const updateModelValue = (event: Event) => {
  let value: string | number | null = (event.target as HTMLInputElement).value

  if (props.isNumber) {
    if (!isNaN(Number(value))) {
      value = value === '' ? null : Number(value)
    }
  }

  emit('update:modelValue', value)
}
</script>

<template>
  <label class="group inline-flex w-full flex-col gap-1.5">
    <span class="relative">
      <input
        v-bind="$attrs"
        v-model="modelValue"
        class="input rounded-b-none rounded-t-md border-b-2 pb-2 pl-2 pt-5 text-mobile outline-none transition-all duration-300 placeholder:transition-all placeholder:duration-300 focus:caret-primary focus:outline-none"
        :class="[
          $slots['after'] || isTypePassword ? 'pr-10' : 'pr-2',
          error ? 'border-error' : 'border-alto hover:border-black focus:border-primary',
          { 'placeholder:text-transparent focus:placeholder:text-base-gray': label },
          { 'cursor-not-allowed bg-anti-flash-white hover:border-inherit': disabled },
        ]"
        :disabled="disabled"
        :type="type"
        @input="updateModelValue"
      />
      <span
        v-if="$slots['before']"
        class="absolute bottom-0 left-0 z-10 flex h-full items-center pl-3"
      >
        <slot name="before" />
      </span>
      <span
        v-if="label"
        class="label pointer-events-none absolute z-10 flex h-full items-center pl-4 transition-all duration-300 group-focus-within:-left-4 group-focus-within:bottom-5 group-focus-within:text-sm group-focus-within:text-black"
        :class="[
          !!modelValue ? '-left-4 bottom-5 text-sm text-black' : 'bottom-0 left-0 text-[#808080]',
        ]"
      >
        <slot name="label">
          <span>{{ label }}<span v-if="required" class="text-primary">*</span></span>
        </slot>
      </span>
      <span
        v-if="$slots['after'] || isTypePassword"
        class="absolute bottom-0 right-0 flex h-full items-center pr-3 text-sm"
      >
        <slot name="after">
          <button type="button" class="text-base-gray" @click="isPassword = !isPassword">
            <Icon v-if="isPassword" name="mdi:eye-off-outline" class="text-xl" />
            <Icon v-else name="mdi:eye-outline" class="text-xl" />
          </button>
        </slot>
      </span>
    </span>
    <AnimateTransition enter-name="fadeIn" out-name="fadeOut" mode="out-in">
      <div v-if="errors?.length && error" class="flex flex-col gap-1 text-red-500">
        <span v-for="item in errors" :key="item">
          {{ item }}
        </span>
      </div>
    </AnimateTransition>
  </label>
</template>

<style scoped lang="scss">
input:-webkit-autofill + .label {
  @apply -left-4 bottom-5 text-sm text-black;
}
</style>
